/* eslint-disable no-undef */
import {React, useState,useEffect} from "react";
import './map.css'
import Axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    Autocomplete,
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsRenderer,LoadScript, StandaloneSearchBox 
  } from '@react-google-maps/api'
import { useRef } from "react";
import {BlueButton} from "../components/BlueButton"
import {DistanceBox} from "../components/DistanceBox";
import { Header } from "../components/Header";




export const Map = () =>{


    // const {isLoaded} = useJsApiLoader({
    //     googleMapsApiKey: 'AIzaSyCJQJxhVpVWG6dS-0mUlgkJ4lAEW-00y5Q',
    //     location: ['places']
        
    // })

    

    // useEffect(() =>{
    //     Axios.get('https://entryapi.albatroxlogistics.com/api/getStores').then((res)=>{
    //         setStores(res.data)
    //         })
       
    //   },[])

     


    const center = { lat: -1.286389, lng: 36.817223}

    const [Route,setRoute] = useState(null);
    const [distance,setDistance] = useState('');
    const [timetoReach,settimetoReach] = useState('');
    
    const [distanceBox,setDistanceBox] = useState(false);
    const [checkOrder,setCheckOrder] = useState([]);
    const [submitResult,setSubmitResult] = useState('');
    const StartDestination = useRef(null);
    const EndDestination = useRef('');
    const[stores,setStores] = useState([])
    const inputRef = useRef();
    // const[stores,setStores] = useState([]);
    const[orderNo,setOrderNo] = useState("");
    const[selectedStore,setSelectedStore] = useState('');
    const[orderDesc,setOrderDesc] = useState('');
    const[orderValue,setOrderValue] = useState('');
    const[customerName,setCustomerName] = useState('');
    const[customerContact,setCustomerContact] = useState('');
    const[landmark,setLandmark] = useState('');
    const[success,setSuccess] = useState('');
    const[instructions,setInstructions] = useState('');
    const[errorMessage, setErrorMessage] = useState('');
    const placesLibrary = ["places"];
    const [searchResult, setSearchResult] = useState("Result: none");
    const[errorMes,setErrorMess] = useState('')
    const[searchOrder,setSearchOrder] = useState([]);
    const[thisOrder,setThisOrder] = useState('EOUMBMIFM');
    const[positiveSearch,setPositiveSearch] = useState('');
    const[negativeSearch,setNegativeSearch] = useState('');
    // const[actualDistance,setActualDistance] = useState('');
    const[amount,setAmout] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };


    const handleClick2 = () => {
      setOpen2(true);
    };

    const handleClose2 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen2(false);
    };


    const handleClick3 = () => {
      setOpen3(true);
    };

    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen3(false);
    };






  
    const postTime = Date.now().toString();
    
    const[selectedPlace,setSelectedPlace] = useState('')

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCJQJxhVpVWG6dS-0mUlgkJ4lAEW-00y5Q',
        libraries: placesLibrary
        
    })

    function checkSuccess(){
      if(searchOrder.length==0){
        // negative = 'Not Found';
        setNegativeSearch('Not Found')
      }
      else{
        // positive= 'Order has been found';
        setPositiveSearch('Found')
      }

    }

    const handleLocationUpdate = () =>{
        const [ place ] = inputRef.current.getPlaces();
            
            // Console the coordinates whenever the location is updated
            if(place){
                console.log(place.formatted_address);
                console.log(place.geometry.location.lat());
                console.log(place.geometry.location.lng());
                setSelectedPlace(place.formatted_address)
            }
        }

        // useEffect(() =>{
        //   Axios.get('https://entryapi.albatroxlogistics.com/api/getSpecificOrder?orderNo='+thisOrder).then((res)=>{
        //       setSearchOrder(res.data)
             
              
        //       })

        // },[thisOrder])


    const submitOrder = (event) =>{
      setThisOrder(event.target.value)

     
  

      // let aDistance = distance;
      // const newDistnce = distance.replace(/km/g,"");
      
     

      // if(distance <10){
      //   setAmout('220');
      // }
      // else{

      // }
      

      
        if(orderNo !='' && orderDesc != '' && orderValue !='' && customerName != '' && customerContact !='' && landmark !=''){
            CalculateDistance(); 
            setDistanceBox(true)
            Axios.post('https://entryapi.albatroxlogistics.com/api/insertOrder',{
            orderNo : orderNo,
            selectedStore : selectedStore,
            orderDesc : orderDesc,
            orderValue: orderValue,
            customerName : customerName,
            customerContact : customerContact,
            location : selectedPlace,
            landmark : landmark,
            postTime: postTime,
            instructions: instructions,
            distance: distance
            
        }).then((result) =>{
          // Axios.post('https://nod3.brane360.com/webhook/2165985c-a01a-4737-81f2-82574b60d9dc',{
          //   orderNo: orderNo,
          //   store:selectedStore  
          // })
          handleClick();
          setNegativeSearch('')
          
          setOrderNo("")
          setOrderDesc("")
          setOrderValue("")
          setCustomerName("")
          setCustomerContact("")
          setLandmark("")
          setSelectedPlace("")
          setSelectedStore("")
          setInstructions("")
          EndDestination.current.value = '';

        }).catch((error) =>{
          
          handleClick2();
        })
     
            // setThisOrder(orderNo)

            // checkSuccess();
            // setSuccess('Submitted Successfully')
           
            
            
      
  
        
        
    }
    else{
        handleClick3();
    }
  
       
    };

    useEffect(() =>{
        Axios.get('https://entryapi.albatroxlogistics.com/api/getStores').then((res)=>{
            setStores(res.data)
            })
       
      },[])
    //   useEffect(() =>{
    //     Axios.get('https://entryapi.albatroxlogistics.com/api/getSpecificOrder?orderNo='+orderNo).then((res)=>{
    //         setCheckOrder(res.data)
    //         })

    //         if(checkOrder.length = 0){
    //             setSubmitResult('Sorry Something Happend! Check your network connection!')
    //         }
    //         else if(checkOrder.length=1){
    //             setSubmitResult('Submitted')
    //         }
       
    //   },[orderNo])

    // useEffect(() =>{
    //   Axios.get('https://api.newamadelivery.co.ke/api/getStores').then((res)=>{
    //       setStores(res.data)
    //       })
     
    // },[])

    if(!isLoaded){
        return<div> Map is Loading... </div>        
    }

    async function CalculateDistance(){
      if(StartDestination.current.value === '' || EndDestination.current.value === ''){
        return;
      }
      const GoogleDirection = new google.maps.DirectionsService();
      const Results = await GoogleDirection.route({
        origin: StartDestination.current.value,
        destination: EndDestination.current.value,
        travelMode: google.maps.TravelMode.DRIVING
      })
      setRoute(Results);
      setDistance(Results.routes[0].legs[0].distance.text)
      settimetoReach(Results.routes[0].legs[0].duration.text);

//       if(orderNo !='' && orderDesc != '' && orderValue !='' && customerName != '' && customerContact !='' && landmark !=''){

//         Axios.post('https://entryapi.albatroxlogistics.com/api/insertOrder',{
//         orderNo : orderNo,
//         selectedStore : selectedStore,
//         orderDesc : orderDesc,
//         orderValue: orderValue,
//         customerName : customerName,
//         customerContact : customerContact,
//         location : selectedPlace,
//         landmark : landmark,
//         postTime: postTime,
//         instructions: instructions,
//         distance: distance
        
//     }).then((result) =>{
//       setNegativeSearch('')
//       setPositiveSearch('Submitted successfully!')
//       setOrderNo("")
//       setOrderDesc("")
//       setOrderValue("")
//       setCustomerName("")
//       setCustomerContact("")
//       setLandmark("")
//       setSelectedPlace("")
//       setSelectedStore("")
//       setInstructions("")
//       EndDestination.current.value = '';

//     }).catch((error) =>{
      
//       setNegativeSearch('NETWORK CONNECTION ERROR! CHECK YOUR CONNECTION')
//     })
 
//         // setThisOrder(orderNo)

//         // checkSuccess();
//         // setSuccess('Submitted Successfully')
       
        
        
  

    
    
// }
// else{
//     setErrorMessage("All Fields are required!")
// }


      

      
    }

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
      }
    
      function onPlaceChanged() {
        if (searchResult != null) {
          const place = searchResult.getPlace();
          const name = place.name;
          const status = place.business_status;
          const formattedAddress = place.formatted_address;
          setSelectedPlace(place.formatted_address)
          // console.log(place);
          console.log(`Name: ${name}`);
          console.log(`Business Status: ${status}`);
          console.log(`Formatted Address: ${formattedAddress}`);
        } else {
          alert("Please enter text");
        }
      }
    

   

    return <>
  
    
         <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  Order submitted successfully
                </Alert>

                
              </Snackbar>


              <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                  <Alert
                    onClose={handleClose2}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                  Failed!Check internet connection.
                </Alert> 
              </Snackbar>


              <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
                  <Alert
                    onClose={handleClose3}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                  All Fields are required
                </Alert> 
              </Snackbar>
           <div className="Content">
             <div className="InputDiv">
               <div className="TextFieldBox">
               
               
                {/* <BlueButton handler={()=>{CalculateDistance(); setDistanceBox(true)}} label={<h3>Get Location</h3>}/> */}
                 </div>
                
              </div>
              <div className="mapCont" >
              <GoogleMap
              
          zoom={15}

          center={center}
          mapContainerClassName="MapBox"
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
           fullscreenControl: false,
        }}
        >
  <Marker position={center}/>
  {Route && (
    <DirectionsRenderer directions={Route} />
  )}
</GoogleMap>

              </div>
              <div className="distanceBox">
              {distanceBox === true && <DistanceBox distance={distance} duration={timetoReach}/>}
            
           
              </div>
              
           </div>
        <div className="entry">
          
        {/* <Home testingName={EndDestination.current.value} distance = {distance}/> */}
        {/* <Neworder loc={EndDestination.current.value} /> */}



      

        
<p className='neworderTitle'>Consignment Details</p>
{/* <p>Search results {searchOrder.length}</p> */}
<div>
{/* {(() => {
        if (searchOrder.length == 0) {
          
          return (
            <p style={{color:'red'}}>Error: Please check your network connection</p>
          )
        } 
        
        else{
          
          return (
            <p style={{color:'green'}}>{success}</p>
          )
        }


      })()} */}
</div>

<p>{submitResult}</p>
<div className="orderInputs">
    <div className="orderInput">
        <select name="" id="" onChange={(e)=>setSelectedStore(e.target.value)} value={selectedStore} ref={StartDestination} >
            <option>-Select Store-</option>
            {stores.map((val,index) => {
            
            return(

                <option className='store'  value={val.storename}>{val.storename}</option>
            )
        })}
        </select>
    </div>
    <div className="orderInput">
    <input value={orderNo} type="text" placeholder='Order Number' onChange={(e)=>setOrderNo(e.target.value)} />
    </div>
    <div className="orderInput">
    <input type="text" value={orderDesc} placeholder='Order Description' onChange={(e)=>setOrderDesc(e.target.value)} />
    </div>
    <div className="orderInput">
    <input type="text" value={orderValue} placeholder='Order Value' onChange={(e)=>setOrderValue(e.target.value)} />
    </div>
    <div className="orderInput">
    <input type="text" value={customerName} placeholder='Customer Name' onChange={(e)=>setCustomerName(e.target.value)} />
    </div>
    <div className="orderInput">
    <input type="text" value={customerContact} placeholder='Customer Contacts' onChange={(e)=>setCustomerContact(e.target.value)} />
    </div>
    <div className="orderInput">
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
          <input
            ref={EndDestination}
            type="text"
            
            placeholder="Delivery Location"
            
          />
        </Autocomplete>
    </div>
    
    <div className="orderInput">
    <input type="text" value={landmark} placeholder='Nearest Landmark' onChange={(e)=>setLandmark(e.target.value)} />
    </div>
    <div className="orderInput">
        <textarea value={instructions} onChange={(e)=>setInstructions(e.target.value)} name="" id="" cols="45" rows="3" placeholder='Any instructions?'></textarea>
    </div>
   
    
    
    
</div>
<button className='submitBtn' value={orderNo} onClick={submitOrder}>Submit Order</button>
<p className="rights">Designed and maintained by <a href="https://www.uidevlabs.com">Uidevlabs Technologies</a></p>
</div>

        </div>


    
    </>
};
export default Map;